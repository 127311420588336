import React, { Component } from "react";
import ReactDOM from "react-dom";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

export default class NavBar extends Component {
    render() {
        var hideNumber = "\"".concat(document.getElementById("hiddenNumProducts").value, "\"");
        return (
            <Disclosure
                as="nav"
                className="bg-nav-bar inset-x-0 shadow-white"
            >
                {({ open }) => (
                    <>
                        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                            <div className="relative flex items-center justify-between h-16">
                                <div className="flex items-center pr-2 static inset-auto">
                                    <div>
                                        <div className="bg-nav-bar hover:bg-gray-700 p-2 rounded-lg">
                                            <a href="/">
                                                <span className="sr-only">
                                                    Home
                                                </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" /* class="bi bi-house"*/ viewBox="0 0 16 16">
                                                    <path  d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                                                    <path  d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 flex items-center justify-center">
                                    <div className="flex-shrink-0 flex items-center">
                                        <a href="/">
                                            <img
                                                className="block lg:hidden h-12 w-auto"
                                                src="/assets/images/logo.png"
                                                alt="Workflow"
                                            />
                                            <img
                                                className="hidden lg:block h-12 w-auto"
                                                src="/assets/images/logo.png"
                                                alt="Workflow"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="flex items-center pr-2 static inset-auto sm:ml-6 sm:pr-0">
                                    <div>
                                        <div className="bg-nav-bar hover:bg-gray-700 p-2 rounded-lg">
                                            <a href="cart">
                                                <span className="sr-only">
                                                    Resumen del pedido
                                                </span>
                                                
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    fill="white"
                                                    className="bi bi-bag"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                                </svg>
                                                <span className='badge badge-warning' id='lblCartCount'>{ document.getElementById("numProducts").value } </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Disclosure>
        );
    }
}

if (document.getElementById("nav-bar")) {
    ReactDOM.render(<NavBar />, document.getElementById("nav-bar"));
}
if(document.getElementById("lblCartCount")) {
    document.getElementById("lblCartCount").hidden = document.getElementById("hiddenNumProducts").value;
}
